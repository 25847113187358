<template>
  <component
    :is="componentToRender"
    :class="{
      'bg-coloration-secondary-100 rounded-3xs py-3 px-2 text-white font-bold shadow-custom-2':
        variant === 'default' && !disabled,
      'rounded-3xs bg-white py-3 px-2 text-primary-700 border border-primary-700 shadow-custom-2':
        variant === 'inverted',
      'text-white py-3 px-2 bg-coloration-error rounded-3xs': variant === 'error' && !disabled,
      'underline gap-2 text-primary-700': variant === 'underlined' && !disabled,
      'underline gap-2 text-red-700': variant === 'underlined-error' && !disabled,
      'text-primary-700 bg-transparent': variant === 'text-only' && !dark,
      'text-white bg-transparent': variant === 'text-only' && dark,
      'text-white py-3 px-2 bg-secondary-600 rounded-3xs':
        disabled && !['inverted', 'text-only', 'underlined', 'underlined-error'].includes(variant),
      'text-secondary-600': disabled && variant === 'text-only',
      'text-secondary-600 border-secondary-600': disabled && variant === 'inverted',
      'underline gap-2 text-secondary-600': ['underlined', 'underlined-error'].includes(variant) && disabled,
      'cursor-not-allowed': disabled,
      'opacity-50': loading,
    }"
    v-bind="$attrs"
    :aria-label="ariaLabel"
    :aria-disabled="disabled || loading"
    :disabled="disabled || loading"
    @click="(e: any) => emit('click', e)"
  >
    <svg-icon v-if="icon" :name="icon" class="w-5 h-5" :class="{ 'flip-x': $i18n.locale === 'ar' }" />
    <div class="relative inline-flex">
      <slot />

      <div v-if="loading" class="[ absolute top-1/2 -left-11 ] transform -translate-y-1/2">
        <Spinner
          class="h-8 w-8"
          :class="{
            'text-white': variant === 'default' && !disabled,
            'text-primary-700': variant === 'inverted',
            'text-red-700': variant === 'underlined-error',
          }"
        />
      </div>
    </div>
    <div
      v-if="variant === 'text-only'"
      class="rounded-full w-12.5 h-12.5 ml-3 [ flex items-center justify-center ]"
      :class="{
        '[ text-white ] gradient ': variant === 'text-only' && !dark && !disabled,
        '[ text-tertiary-700 ]  bg-white  ': variant === 'text-only' && dark && !disabled,
        '[ text-white ]  bg-secondary-600  ': variant === 'text-only' && disabled,
      }"
    >
      <svg-icon
        name="arrow-left"
        width="20"
        height="16"
        :class="{
          '[ text-tertiary-700 ]    ': variant === 'text-only' && dark && !disabled,
          'transform rotate-180': $i18n.locale == 'en',
        }"
      />
    </div>
  </component>
</template>

<script setup lang="ts" generic="T">
type ComponentType = 'button' | 'a' | 'AppLink';
type Prop = {
  as?: ComponentType;
  ariaLabel?: string;
  variant?: 'default' | 'inverted' | 'text-only' | 'underlined' | 'underlined-error' | 'error';
  icon?: string;
  disabled?: boolean;
  dark?: boolean;
  loading?: boolean;
};
const props = withDefaults(defineProps<Prop>(), {
  as: 'button',
  ariaLabel: '',
  variant: 'default',
  icon: '',
  disabled: false,
  dark: false,
  loading: false,
});

const isAutoImportedComponent = (name: ComponentType) => {
  return ['AppLink'].includes(name);
};

const componentToRender = computed(() => {
  if (isAutoImportedComponent(props.as)) {
    return props.as;
  }

  return props.as;
});

const emit = defineEmits<{
  (e: 'click', event: any): void;
}>();
</script>

<style lang="postcss">
.gradient {
  background-image: linear-gradient(to top, #1ba4e6, rgba(27, 164, 230, 0.7));
}
</style>
